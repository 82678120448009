<template>

  <v-dialog
    width="900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Workgroups for <span class="font-weight-black">Name of the User</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <template>

          <v-row class="py-2">
            <v-col>
              <v-switch
                inset
                label="City Manager Office"
                color="secondary"
                class="mt-0 pa-0"
                v-model="switch1"
                hide-details
              ></v-switch>
              <p class="important--text font-italic pl-14 mb-0">Deactivated</p>
            </v-col>
            <v-col class="py-0 d-flex align-center text-caption">
              Sed nec est sit amet magna facilisis mattis. Pellentesque efficitur metus urna. Duis in tortor nunc. Vivamus sit amet nisl ac ante maximus molestie. Proin sed diam quis magna tristique fringilla.
            </v-col>
          </v-row>

          <v-row>
            <v-divider class="custom-divider"></v-divider>
          </v-row>

          <v-row class="py-2">
            <v-col>
              <v-switch
                inset
                label="City Secretary Office"
                color="secondary"
                class="mt-0 pa-0"
                v-model="switch2"
                hide-details
              ></v-switch>
            </v-col>
            <v-col class="py-0 d-flex align-center text-caption">
              Suspendisse libero urna, scelerisque posuere ligula aliquet, condimentum elementum sem. Proin rutrum ac mauris eu feugiat.
            </v-col>
          </v-row>

        </template>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'UserWorkgroups',
  data () {
    return {
      switch1: true,
      switch2: false
    }
  },
  props: {
    value: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
