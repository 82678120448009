<template>

  <v-dialog
    width="900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Board Access for <span class="font-weight-black">Name of the User</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <div v-for="(board, index) in boards" :key="board.name">

          <v-row class="py-2">
            <v-col class="d-flex align-center">
              <span class="font-weight-bold">
                {{ board.name }}
              </span>
            </v-col>
            <v-col>
              <span class="d-flex flex-row align-center">
                <v-select
                  :items="boardTypes"
                  label="Type of user"
                  item-value="name"
                  item-text="name"
                  outlined
                  dense
                  v-model="boards[index].type"
                  hide-details
                  v-on:change="changeUserType($event,index)"
                ></v-select>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="regularGrey"
                      size="18"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>
                    Explanation
                  </span>
                </v-tooltip>
              </span>
            </v-col>
            <v-col class="d-flex align-center justify-end">
              <v-switch
                inset
                color="secondary"
                label="Show privileges"
                hide-details
                class="ma-0"
                v-model="boards[index].privileges"
                :disabled="boards[index].type==='Custom' || boards[index].type==='No privileges'"
              >
              </v-switch>
            </v-col>
          </v-row>

          <v-expand-transition>
            <v-row v-if="boards[index].privileges==true">
              <v-col class="almostWhiteGrey mb-6 custom-view-list pa-4">

                <v-row>
                  <v-col>
                    <span class="font-weight-bold secondary--text text-uppercase d-flex mb-4">
                      Items
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="regularGrey"
                            size="18"
                            class="ml-3"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        <span>
                          Explanation
                        </span>
                      </v-tooltip>
                    </span>
                    <span class="d-flex flex-column mb-2">
                       <span class="d-flex align-start">
                        <v-checkbox
                          label=""
                          hide-details
                          class="ma-0 pa-0"
                        ></v-checkbox>
                        Can CREATE
                      </span>
                      <span class="d-flex align-start">
                        <v-checkbox
                          hide-details
                          class="ma-0 pa-0"
                        ></v-checkbox>
                        Can EDIT and DELETE created from others
                      </span>
                    </span>
                  </v-col>
                  <v-col>
                    <span class="font-weight-bold secondary--text text-uppercase d-flex mb-4">
                      Meetings
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="regularGrey"
                            size="18"
                            class="ml-3"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        <span>
                          Explanation
                        </span>
                      </v-tooltip>
                    </span>
                    <span class="d-flex flex-column mb-2">
                      <span class="d-flex align-start">
                        <v-checkbox
                          label=""
                          hide-details
                          class="ma-0 pa-0"
                        ></v-checkbox>
                        Can CREATE
                      </span>
                      <span class="d-flex align-start">
                        <v-checkbox
                          hide-details
                          class="ma-0 pa-0"
                        ></v-checkbox>
                        Can EDIT
                      </span>
                    </span>
                  </v-col>
                  <v-col>
                    <span class="font-weight-bold secondary--text text-uppercase d-flex mb-4">
                      Settings and Templates
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="regularGrey"
                            size="18"
                            class="ml-3"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        <span>
                          Explanation
                        </span>
                      </v-tooltip>
                    </span>
                    <span class="d-flex flex-column mb-2">
                      <span class="d-flex align-start">
                        <v-checkbox
                          label=""
                          hide-details
                          class="ma-0 pa-0"
                        ></v-checkbox>
                        Can MANAGE
                      </span>
                      <span class="d-flex align-start">
                        <v-checkbox
                          hide-details
                          class="ma-0 pa-0"
                        ></v-checkbox>
                        Can EDIT default board settings
                      </span>
                    </span>
                  </v-col>
                  <v-col class="flex-grow-0 d-flex align-end">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          size="32"
                          v-bind="attrs"
                          v-on="on"
                          color="important"
                          depressed
                          fab
                          small
                        >
                          <v-icon color="white">mdi-cached</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        Reset privileges
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
          </v-expand-transition>

          <v-row>
            <v-divider class="custom-divider"></v-divider>
          </v-row>

        </div>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'UserBoards',
  data () {
    return {
      boardTypes: ['Contributor', 'Assistant', 'Super-User', 'Custom', 'No privileges'],
      boards: [
        { name: 'City Council', type: '', privileges: '' },
        { name: 'Planning and Zoning Commission', type: '', privileges: '' },
        { name: 'Parks and Recreation Commission', type: '', privileges: '' }
      ]
    }
  },
  props: {
    value: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    changeUserType (event, index) {
      this.boards[index].type = event
      if (event === 'Custom') this.boards[index].privileges = true
      if (event === 'No privileges') this.boards[index].privileges = false
    }
  }
})
</script>
