<template>
  <v-navigation-drawer
    fixed
    temporary
    right
    width="600"
    v-model="show"
  >

    <div class="py-6 px-12">

      <v-row>
        <v-col>
          <h1 class="darkGrey--text font-weight-medium">
            User Name
          </h1>
        </v-col>
      </v-row>

      <v-row class="mb-10">
        <v-col>
            <span class="d-flex flex-column">
                <span class="d-flex align-center">
                  <span>
                    <v-icon color="important" size="32">mdi-timer-sand</v-icon>
                  </span>
                  <span class="d-flex flex-column">
                    <span class="text-body-2">user@email.com</span>
                    <span class="text-body-2 important--text">Not verified</span>
                  </span>
                </span>
            </span>
        </v-col>
        <v-col class="d-flex align-center">
          <span class="d-flex align-center">
            <v-icon color="secondary" size="24">mdi-check-circle-outline</v-icon>
            <span class="secondary--text text-uppercase text-caption font-weight-medium ml-2">Active</span>
          </span>
          <v-spacer></v-spacer>
          <span class="d-flex align-center">
            <v-icon color="secondary" size="24">mdi-shield-account</v-icon>
            <span class="secondary--text text-uppercase text-caption font-weight-medium ml-2">Administrator</span>
          </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
              <span class="d-flex align-center">
                <v-icon color="secondary" size="30">mdi-gavel</v-icon>
                <span class="text-uppercase darkGrey--text ml-4 font-weight-medium text-body-1">3 Boards</span>
              </span>
        </v-col>
      </v-row>
      <v-row class="mb-16">
        <v-col>
          <v-expansion-panels flat accordion class="custom-panel-separator">
            <v-expansion-panel>
              <v-expansion-panel-header class="pa-0">
                <span class="font-weight-light">
                  City Council
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0 veryLightGrey">
                <span class="d-flex flex-column pt-4 darkGrey--text text-body-2">
                    User can CREATE, EDIT and DELETE their own items
                </span>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="pa-0">
                <span class="font-weight-light">
                  Planning and Zoning Commission
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0 veryLightGrey">
                <span class="d-flex flex-column pt-4 darkGrey--text text-body-2">
                    User can CREATE, EDIT and DELETE their own items
                </span>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="pa-0">
                <span class="font-weight-light">
                  Parks and Recreation Commission
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0 veryLightGrey">
                <span class="d-flex flex-column pt-4 darkGrey--text text-body-2">
                    User can CREATE, EDIT and DELETE their own items
                </span>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
              <span class="d-flex align-center">
                <v-icon color="secondary" size="30">mdi-briefcase</v-icon>
                <span class="text-uppercase darkGrey--text ml-4 font-weight-medium text-body-1">2 Workgroups</span>
              </span>
        </v-col>
      </v-row>
      <v-row class="mb-16">
        <v-col>
          <v-expansion-panels flat accordion class="custom-panel-separator">
            <v-expansion-panel>
              <v-expansion-panel-header class="pa-0">
                <span class="font-weight-light">
                  City Manager Office
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0 veryLightGrey">
                <span class="d-flex flex-column pt-4 darkGrey--text text-body-2">
                  <span class="mb-6 text-caption">
                    Duis at interdum lectus. Aenean non justo eget mi bibendum egestas sit amet eleifend sapien. Phasellus egestas faucibus tortor, vel fringilla purus dignissim feugiat.
                  </span>
                  <span>
                    <span class="font-weight-bold">11 MEMBERS</span><span class="text-caption ml-4">that can VIEW and EDIT</span>
                  </span>
                  <span class="text-body-2 custom-view-list">
                    <ul class="custom-list-very-big">
                      <li class="font-weight-light" v-for="item in membersSample" :key="item">
                        {{ item }}
                      </li>
                    </ul>
                  </span>
                </span>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="pa-0">
                <span class="font-weight-light">
                  City Secretary Office
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0 veryLightGrey">
                <span class="d-flex flex-column pt-4 darkGrey--text text-body-2">
                  <span class="mb-6 text-caption">
                    Duis at interdum lectus. Aenean non justo eget mi bibendum egestas sit amet eleifend sapien. Phasellus egestas faucibus tortor, vel fringilla purus dignissim feugiat.
                  </span>
                  <span>
                    <span class="font-weight-bold">11 MEMBERS</span> that can VIEW and EDIT
                  </span>
                  <span class="text-body-2 custom-view-list">
                    <ul class="custom-list-very-big">
                      <li class="font-weight-light" v-for="item in membersSample" :key="item">
                        {{ item }}
                      </li>
                    </ul>
                  </span>
                </span>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-btn depressed @click="show=false">
        Close
      </v-btn>

    </div>

  </v-navigation-drawer>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'UserView',
  props: {
    value: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      membersSample: ['Charles Simon', 'Vee Caron', 'Rico Reis', 'Raph Leroux', 'Tony Cappozi', 'Kyler Miles', 'Sean Canlas', 'Stephen Cawley', 'Arthur da Silva', 'Miguel Reis', 'Alline Pereira']
    }
  },
  methods: {
    close () {
      this.$emit('closed')
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
