<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-icon color="secondary" size="36" class="mr-3">mdi-account-group</v-icon>
          <h1 class="darkGrey--text">Users</h1>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Users
            </span>
          </v-tooltip>
        </span>

        <v-spacer></v-spacer>

        <v-btn
          depressed
          height="40"
          color="orange lighten-2"
          class="white--text"
          @click="openModal('createUpdate', 'create')"
        >
          <v-icon size="18">mdi-plus</v-icon> Add User
        </v-btn>

      </v-col>
    </v-row>

    <v-row class="my-6">
      <v-col>
        <v-divider class="custom-divider"></v-divider>
      </v-col>
    </v-row>

    <v-row class="d-flex mb-6">
      <v-col class="d-flex flex-row">
        <v-checkbox
          color="secondary"
          label="Show deactivated Users"
          hide-details
          class="custom-checkbox-small"
        ></v-checkbox>
      </v-col>
      <v-col>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="users"
            class="elevation-1"
            :footer-props="{
              'items-per-page-options': [10, 20, 30]
            }"
            :loading="loading"
            :search="search"
          >
            <template v-slot:item.name="{ item }">
              <span class="font-weight-bold py-6 d-flex">
                {{ item.name }}{{ item.temp_name }}
              </span>
            </template>
            <template v-slot:item.administrator="{ item }">
              <span v-if="item.isAdmin == 1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="28"
                        v-bind="attrs"
                        v-on="on"
                        color="secondary"
                      >
                        mdi-shield-account
                      </v-icon>
                  </template>
                  <span>
                    {{ item.first_name }} is administrator
                  </span>
                </v-tooltip>
              </span>
            </template>
            <template v-slot:item.email="{ item }">
              <span class="d-flex flex-column">
                  <span class="d-flex align-center">
                    <span v-if="item.verified===false">
                      <v-icon color="important" size="32">mdi-timer-sand</v-icon>
                    </span>
                    <span class="d-flex flex-column">
                      <span>{{ item.email }}</span>
                      <span v-if="item.verified===false" class="important--text">Not verified</span>
                    </span>
                  </span>
              </span>
            </template>
            <template v-slot:item.workgroups="{ item }">
              <v-tooltip top v-if="item.workgroups>0">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.workgroups }}
                  </v-chip>
                </template>
                <span class="custom-tooltip-list">
                  <v-row>
                    <v-col>
                      <span class="custom-tooltip-title">
                        WORKGROUPS
                      </span>
                      <ul>
                        <li v-for="item in workgroupsSample" :key="item">
                          {{ item }}
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </span>
              </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex flex-row justify-center">

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="darkGrey"
                      size="18"
                      v-bind="attrs"
                      v-on="on"
                      @click="viewUser(item)"
                    >
                      <v-icon size="20">
                        mdi-eye-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    View details
                  </span>
                </v-tooltip>

                <v-menu transition="slide-x-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon size="20">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item
                        v-for="(itemMenu, index) in itemsMenu"
                        :key="index"
                        @click="openModal(itemMenu.actions, itemMenu.optional)"
                        dense
                      >
                        <v-list-item-icon>
                          <v-icon
                            :color="itemMenu.type? itemMenu.type:'darkGrey'"
                          >
                            {{ itemMenu.icon }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            :class="itemMenu.type? itemMenu.type+'--text':'darkGrey--text'"
                          >
                            {{ itemMenu.text }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>

              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <user-view v-model="modals.view" v-if="modals.view" />
    <user-create-update @reload="getDataFromApi" v-model="modals.createUpdate" :modeType="modeType"></user-create-update>
    <user-boards v-model="modals.boards"></user-boards>
    <user-workgroups v-model="modals.workgroups"></user-workgroups>
    <user-new-password v-model="modals.newPassword"></user-new-password>
    <user-admin v-model="modals.admin"></user-admin>
    <user-not-admin v-model="modals.notAdmin"></user-not-admin>
    <user-activate v-model="modals.activate"></user-activate>
    <user-deactivate v-model="modals.deactivate"></user-deactivate>
    <user-delete v-model="modals.delete"></user-delete>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import UserBoards from '@/components/Users/UserBoards'
import UserCreateUpdate from '@/components/Users/UserCreateUpdate'
import UserWorkgroups from '@/components/Users/UserWorkgroups'
import UserNewPassword from '@/components/Users/UserNewPassword'
import UserAdmin from '@/components/Users/UserAdmin'
import UserNotAdmin from '@/components/Users/UserNotAdmin'
import UserDeactivate from '@/components/Users/UserDeactivate'
import UserActivate from '@/components/Users/UserActivate'
import UserDelete from '@/components/Users/UserDelete'
import UserView from '@/components/Users/UserView'
import Users from '@/models/admin/Users'
import axios from 'axios'

export default Vue.extend({
  name: 'Users',
  components: {
    Portal,
    UserCreateUpdate,
    UserBoards,
    UserWorkgroups,
    UserNewPassword,
    UserAdmin,
    UserNotAdmin,
    UserDeactivate,
    UserActivate,
    UserDelete,
    UserView
  },
  data: () => ({
    modeType: '',
    modals: {
      createUpdate: false,
      boards: false,
      workgroups: false,
      newPassword: false,
      admin: false,
      activate: false,
      notAdmin: false,
      deactivate: false,
      delete: false,
      view: false,
      deactivatedUsers: false,
      options: {}
    },
    breadcrumbsItems: [
      {
        text: 'Admin Panel',
        disabled: false,
        href: ''
      },
      {
        text: 'Users',
        disabled: true,
        href: ''
      }
    ],
    itemsMenu: [
      { icon: 'mdi-pencil', text: 'Edit Name / Email', actions: 'createUpdate', optional: 'edit' },
      { icon: 'mdi-gavel', text: 'Board Access', actions: 'boards' },
      { icon: 'mdi-briefcase', text: 'Workgroups', actions: 'workgroups' },
      { icon: 'mdi-key', text: 'New password', actions: 'newPassword' },
      { icon: 'mdi-shield-account', text: 'Set as Administrator', actions: 'admin' },
      { icon: 'mdi-check-circle-outline', text: 'Activate', actions: 'activate' },
      { icon: 'mdi-account-off-outline', text: 'Not Administrator', type: 'important', actions: 'notAdmin' },
      { icon: 'mdi-close-circle-outline', text: 'Deactivate', type: 'important', actions: 'deactivate' },
      { icon: 'mdi-delete', text: 'Delete', type: 'important', actions: 'delete' }
    ],
    headers: [
      { text: 'Name', value: 'name' },
      { text: '', value: 'administrator' },
      { text: 'Email', value: 'email', sortable: false },
      { text: 'Workgroups', value: 'workgroups', sortable: false, align: 'center' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'center' }
    ],
    usersSample: [
      { name: 'Rico Reis', administrator: false, verified: true, email: 'rico@email.com', workgroups: 4, status: 'active' },
      { name: 'Vee Caron', administrator: false, verified: true, email: 'vee@email.com', workgroups: 14, status: 'active' },
      { name: 'Charles Simon', administrator: true, verified: true, email: 'charles@email.com', workgroups: 4, status: 'active' },
      { name: 'Raph Leroux', administrator: false, verified: true, email: 'raph@email.com', workgroups: 0, status: 'inactive' },
      { name: 'Tony Cappozi', administrator: false, verified: false, email: 'tony@email.com', workgroups: 1, status: 'active' }
    ],
    boardsSample: ['City Manager Office', 'City Secretary Office'],
    workgroupsSample: ['Management Team', 'Planning Department', 'Public Works Department'],
    loading: false,
    search: '',
    users: []
  }),
  methods: {
    openModal (item, optional) {
      if (optional) this.modeType = optional
      this.modals[item] = true
    },
    viewUser (user) {
      this.modals.view = true
    },
    getDataFromApi () {
      this.loading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      let orderBy = null
      if (sortBy !== undefined && sortBy[0] !== undefined) {
        const sortDir = sortDesc[0] ? '-' : ''
        orderBy = sortDir + sortBy[0]
      }

      Users
        .page(page)
        .where('users.first_name', this.search)
        .where('users.last_name', this.search)
        .where('user_meta.meta_value', this.search)
        .where('id', this.$store.state.client.activeClient.clientID)
        .orderBy(orderBy)
        .get()
        .then(response => {
          this.users = response.data
          this.totalUsers = response.total
          this.loading = false
        })
    }
  },
  created () {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'client/setActiveClient') {
        if (state.activeClient !== null) {
          this.getDataFromApi()
        }
      }
    })
  },
  beforeDestroy () {
    this.unsubscribe()
  },
  mounted () {
    this.options = {
      page: 1,
      itemsPerPage: 10
    }
    this.getDataFromApi()
  }
})
</script>
