<template>

  <v-dialog
    width="600"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

        <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
          <span>
            <span v-if="modeType=='create'">New User</span>
            <span v-if="modeType=='edit'">Edit User</span>
          </span>
          <span v-if="modeType=='edit'"><span class="font-weight-black">Charles Simon</span></span>
        </v-card-title>

        <span class="modal-close-btn">
          <v-btn
            icon
            color="grey"
            @click="show=false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </span>

        <v-card-text v-if="modeType=='create'">

          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="First Name"
                v-model="form.firstName"
                :rules="nameRules"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                label="Last Name"
                v-model="form.lastName"
                :rules="nameRules"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="Email"
                ref="email"
                v-model="form.email"
                :rules="emailRules"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="modeType=='create'" class="mb-8 mt-6">
            <v-col>
              <v-switch
                inset
                label="Initially set user as deactivated"
                color="important"
                class="mt-0 pa-0 mr-4"
                v-model="form.isDisabled"
                hide-details
              ></v-switch>
            </v-col>
          </v-row>

          <v-row v-if="modeType=='create'">
            <v-col class="veryLightGrey pa-12 align-center flex-row d-flex">
              <v-switch
                inset
                color="secondary"
                class="mt-0 pa-0 mr-4"
                v-model="form.isAdmin"
                hide-details
              >
              </v-switch>
              <span class="text-body-1">
                This user will be set as <span class="font-weight-black">ADMINISTRATOR</span>
              </span>
            </v-col>
          </v-row>

        </v-card-text>
        <v-card-text v-if="modeType=='edit'">

          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="First Name"
                v-model="form.firstName"
                :rules="nameRules"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                label="Last Name"
                v-model="form.lastName"
                :rules="nameRules"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="Email"
                ref="email"
                v-model="form.email"
                :rules="emailRules"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox
                v-model="this.admin.disconnect"
                label=""
                required
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="d-flex align-center justify-center">
          <v-spacer></v-spacer>
          <v-btn depressed width="130" @click="show=false">Cancel</v-btn>
          <v-btn depressed width="130" color="secondary" @click="saveUser" :disabled="!valid || !this.form.firstName || !this.form.lastName || !this.form.email">
            <v-progress-circular v-if="saving===true" indeterminate size="16"></v-progress-circular>
            <span v-if="modeType=='create'">Add</span>
            <span v-if="modeType=='edit'">Update</span>
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-form>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  name: 'UserCreateUpdate',
  props: {
    value: {
      required: true,
      type: Boolean
    },
    modeType: {
      required: true,
      type: String
    }
  },
  data: () => ({
    valid: true,
    saving: false,
    form: {
      clientId: '',
      isDisabled: false,
      isAdmin: false
    },
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 50) || 'Name must be less than 50 characters'
    ],
    emailRules: [
      v => !!v || 'Email is required',
      v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
      v => (v && v.length <= 128) || 'E-mail must be less than 128 characters'
    ]
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    saveUser () {
      this.saving = true
      this.form.clientId = this.$store.state.client.activeClient.clientID
      const axiosAction = (this.modeType === 'create') ? 'post' : 'put'
      const axiosUrl = (this.modeType === 'edit') ? '/api/admin/users/' + this.itemContent.id : '/api/admin/users'
      axios({
        method: axiosAction,
        url: process.env.VUE_APP_BACKEND_URL + axiosUrl,
        data: this.form
      }).then((response) => {
        this.saving = false
        if (response.data.status === 'success') {
          this.form.clientId = ''
          this.form.isDisabled = false
          this.form.isAdmin = false
          this.form.firstName = ''
          this.form.lastName = ''
          this.form.email = ''
          this.show = false
          this.$emit('reload', true)
        } else {
          this.$refs.email.focus()
        }
      })
    }
  }
})
</script>
