var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"900","persistent":this.$store.state.modalNotCloseOutside,"no-click-animation":this.$store.state.modalWithoutAnimation},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"pa-8 veryDarkGrey--text",attrs:{"elevation":"0","rounded":"0"}},[_c('v-card-title',{staticClass:"d-flex justify-center headline flex-column mb-6 align-start"},[_c('span',[_vm._v("Board Access for "),_c('span',{staticClass:"font-weight-black"},[_vm._v("Name of the User")])])]),_c('span',{staticClass:"modal-close-btn"},[_c('v-btn',{attrs:{"icon":"","color":"grey"},on:{"click":function($event){_vm.show=false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',_vm._l((_vm.boards),function(board,index){return _c('div',{key:board.name},[_c('v-row',{staticClass:"py-2"},[_c('v-col',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(board.name)+" ")])]),_c('v-col',[_c('span',{staticClass:"d-flex flex-row align-center"},[_c('v-select',{attrs:{"items":_vm.boardTypes,"label":"Type of user","item-value":"name","item-text":"name","outlined":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.changeUserType($event,index)}},model:{value:(_vm.boards[index].type),callback:function ($$v) {_vm.$set(_vm.boards[index], "type", $$v)},expression:"boards[index].type"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"regularGrey","size":"18"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(" Explanation ")])])],1)]),_c('v-col',{staticClass:"d-flex align-center justify-end"},[_c('v-switch',{staticClass:"ma-0",attrs:{"inset":"","color":"secondary","label":"Show privileges","hide-details":"","disabled":_vm.boards[index].type==='Custom' || _vm.boards[index].type==='No privileges'},model:{value:(_vm.boards[index].privileges),callback:function ($$v) {_vm.$set(_vm.boards[index], "privileges", $$v)},expression:"boards[index].privileges"}})],1)],1),_c('v-expand-transition',[(_vm.boards[index].privileges==true)?_c('v-row',[_c('v-col',{staticClass:"almostWhiteGrey mb-6 custom-view-list pa-4"},[_c('v-row',[_c('v-col',[_c('span',{staticClass:"font-weight-bold secondary--text text-uppercase d-flex mb-4"},[_vm._v(" Items "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"regularGrey","size":"18"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(" Explanation ")])])],1),_c('span',{staticClass:"d-flex flex-column mb-2"},[_c('span',{staticClass:"d-flex align-start"},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"label":"","hide-details":""}}),_vm._v(" Can CREATE ")],1),_c('span',{staticClass:"d-flex align-start"},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"hide-details":""}}),_vm._v(" Can EDIT and DELETE created from others ")],1)])]),_c('v-col',[_c('span',{staticClass:"font-weight-bold secondary--text text-uppercase d-flex mb-4"},[_vm._v(" Meetings "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"regularGrey","size":"18"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(" Explanation ")])])],1),_c('span',{staticClass:"d-flex flex-column mb-2"},[_c('span',{staticClass:"d-flex align-start"},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"label":"","hide-details":""}}),_vm._v(" Can CREATE ")],1),_c('span',{staticClass:"d-flex align-start"},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"hide-details":""}}),_vm._v(" Can EDIT ")],1)])]),_c('v-col',[_c('span',{staticClass:"font-weight-bold secondary--text text-uppercase d-flex mb-4"},[_vm._v(" Settings and Templates "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"regularGrey","size":"18"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(" Explanation ")])])],1),_c('span',{staticClass:"d-flex flex-column mb-2"},[_c('span',{staticClass:"d-flex align-start"},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"label":"","hide-details":""}}),_vm._v(" Can MANAGE ")],1),_c('span',{staticClass:"d-flex align-start"},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"hide-details":""}}),_vm._v(" Can EDIT default board settings ")],1)])]),_c('v-col',{staticClass:"flex-grow-0 d-flex align-end"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"size":"32","color":"important","depressed":"","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-cached")])],1)]}}],null,true)},[_c('span',[_vm._v(" Reset privileges ")])])],1)],1)],1)],1):_vm._e()],1),_c('v-row',[_c('v-divider',{staticClass:"custom-divider"})],1)],1)}),0),_c('v-card-actions',{staticClass:"d-flex align-center justify-center"},[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","width":"130"},on:{"click":function($event){_vm.show=false}}},[_vm._v("Ok")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }